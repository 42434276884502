
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { getArtworks } from 'api/artworks';
import { OnScreenComponent } from 'components/abstract/OnScreenComponent';
import { ArtistCardProps } from 'components/artists/ArtistCard';
import { ArtworkCard, ArtworkCardProps } from 'components/artwork/ArtworkCard';
import { BannerCarousel } from 'components/carousels/BannerCarousel';
import { LatestArtworksAsksContainer } from 'components/carousels/LatestArtworksAsksContainer';
import { LatestArtworksBidsContainer } from 'components/carousels/LatestArtworksBidsContainer';
import { LatestArtworksSalesContainer } from 'components/carousels/LatestArtworksSalesContainer';
import { NewReleasesContainer } from 'components/carousels/NewReleasesContainer';
import { PrintsContainer } from 'components/carousels/PrintsContainer';
import { ReleasesContainer } from 'components/carousels/ReleasesContainer';
import { SculpturesContainer } from 'components/carousels/SculpturesContainer';
import { SearchedArtworksContainer } from 'components/carousels/SearchedArtworksContainer';
import { TrendingArtistsCarousel } from 'components/carousels/TrendingArtistsCarousel';
import { TrendingArtworksCarousel } from 'components/carousels/TrendingArtworksCarousel';
import { Button } from 'components/common/Button';
import { CategoriesContainer } from 'components/common/CategoriesContainer';
import { CategoryContainer } from 'components/common/CategoryContainer';
import { Icon } from 'components/common/Icon';
import { Meta } from 'components/common/Meta';
import { ReleaseCardSkeleton } from 'components/release-calendar/ReleaseCardSkeleton';
import routes from 'constants/routes';
import { HomeLayout } from 'layouts/HomeLayout';
import { GetStaticProps } from 'next';
import useTranslation from 'next-translate/useTranslation';
import qs from 'qs';
import { ReactElement, ReactNode, Suspense, lazy } from 'react';
import Skeleton from 'react-loading-skeleton';
import { fetchFeaturedArtists, fetchFeaturedArtworks } from 'services/homepage';
import { range } from 'utils/arrays';
const Categories = lazy(() => import('components/common/Categories').then(m => ({ default: m.Categories })));
const LatestArworksAsks = lazy(() => import('components/carousels/LatestArtworksAsksCarousel').then(m => ({
    default: m.LatestArworksAsksCarousel
})));
const LatestArtworksBids = lazy(() => import('components/carousels/LatestArtworksBidsCarousel').then(m => ({
    default: m.LatestArtworksBidsCarousel
})));
const NewReleases = lazy(() => import('components/carousels/NewReleasesCarousel').then(m => ({
    default: m.NewReleasesCarousel
})));
const LatestArtworksSales = lazy(() => import('components/carousels/LatestArtworksSalesCarousel').then(m => ({
    default: m.LatestArtworksSalesCarousel
})));
const Prints = lazy(() => import('components/carousels/PrintsCarousel').then(m => ({
    default: m.PrintsCarousel
})));
const Sculptures = lazy(() => import('components/carousels/SculpturesCarousel').then(m => ({
    default: m.SculpturesCarousel
})));
const SearchedArtworks = lazy(() => import('components/carousels/SearchedArtworksCarousel').then(m => ({
    default: m.SearchedArtworksCarousel
})));
const BackToArtistBanner = lazy(() => import('components/homepage/BackToArtistBanner').then(m => ({
    default: m.BackToArtistBanner
})));
const Releases = lazy(() => import('components/carousels/ReleasesCarousel').then(m => ({
    default: m.ReleasesCarousel
})));
const skeletonsRange = range(0, 6);
function ArtworksSkeleton({ ...props }) {
    return (<>
      {skeletonsRange.map(i => (<ArtworkCard key={i} {...props}/>))}
    </>);
}
function ReleasesSkeleton({ ...props }) {
    return (<>
      {skeletonsRange.map(i => (<ReleaseCardSkeleton key={i} {...props}/>))}
    </>);
}
const fallbacks = {
    searchedArtworks: (<SearchedArtworksContainer>
      <ArtworksSkeleton />
    </SearchedArtworksContainer>),
    latestAsks: (<LatestArtworksAsksContainer>
      <ArtworksSkeleton />
    </LatestArtworksAsksContainer>),
    releases: (<ReleasesContainer>
      <ReleasesSkeleton />
    </ReleasesContainer>),
    latestBids: (<LatestArtworksBidsContainer>
      <ArtworksSkeleton />
    </LatestArtworksBidsContainer>),
    newReleases: (<NewReleasesContainer>
      <ArtworksSkeleton />
    </NewReleasesContainer>),
    latestSales: (<LatestArtworksSalesContainer>
      <ArtworksSkeleton />
    </LatestArtworksSalesContainer>),
    categories: (<CategoriesContainer className='container my-5'>
      {range(0, 4).map(i => (<CategoryContainer key={i}>
          <Skeleton className='absolute left-0 top-0 h-full w-full'/>
        </CategoryContainer>))}
    </CategoriesContainer>),
    prints: (<PrintsContainer>
      <ArtworksSkeleton />
    </PrintsContainer>),
    sculptures: (<SculpturesContainer>
      <ArtworksSkeleton />
    </SculpturesContainer>),
    backToArtistBanner: (<div className='container my-8 h-[500px] md:my-6 md:h-[300px]'/>)
};
type Props = {
    initialFeaturedArtworks?: ArtworkCardProps[] | null;
    initialFeaturedArtists?: ArtistCardProps[] | null;
};
const Home = ({ initialFeaturedArtworks, initialFeaturedArtists }: Props) => {
    const { t } = useTranslation();
    return (<>
      <Meta imageName='social-homepage' pageDescription={t('home:page_desc')} pageTitle={t('home:page_title')}/>

      <BannerCarousel slides={[
            {
                src: '/home/carousel-1-resized.jpg',
                priority: true,
                loading: 'eager',
                reversed: true,
                content: (<Slide title={t('home:buy_&_sell')} description={t('home:from_1000s_of_artworks')} cta={t('home:browse_artworks')} href={routes.artworks}/>)
            },
            {
                src: '/home/carousel-2.jpg',
                content: (<Slide title={t('home:discover')} description={t('home:extraordinary_mediums')} cta={t('home:browse_artists')} href={routes.artists}/>)
            },
            {
                src: '/home/carousel-3.jpg',
                reversed: true,
                content: (<Slide title={t('home:give_back')} description={t('home:fees_back_to_artists')} cta={<>
                    <p className='sr-only'>
                      {t('home:learn_more_about_artists_royalties')}
                    </p>{' '}
                    <span aria-hidden>{t('home:learn_more')}</span>
                  </>} href={routes.howItWorks.forArtists}/>)
            },
        ]}/>

      <TrendingArtworksCarousel trendingArtworks={initialFeaturedArtworks}/>

      <TrendingArtistsCarousel trendingArtists={initialFeaturedArtists}/>

      <OnScreenComponent fallback={fallbacks.searchedArtworks}>
        <Suspense fallback={fallbacks.searchedArtworks}>
          <SearchedArtworks minCount={6}/>
        </Suspense>
      </OnScreenComponent>

      <OnScreenComponent fallback={fallbacks.latestAsks}>
        <Suspense fallback={fallbacks.latestAsks}>
          <LatestArworksAsks />
        </Suspense>
      </OnScreenComponent>

      <OnScreenComponent fallback={fallbacks.releases}>
        <Suspense fallback={fallbacks.releases}>
          <Releases />
        </Suspense>
      </OnScreenComponent>

      <OnScreenComponent fallback={fallbacks.latestBids}>
        <Suspense fallback={fallbacks.latestBids}>
          <LatestArtworksBids />
        </Suspense>
      </OnScreenComponent>

      <OnScreenComponent fallback={fallbacks.backToArtistBanner}>
        <Suspense fallback={fallbacks.backToArtistBanner}>
          <BackToArtistBanner />
        </Suspense>
      </OnScreenComponent>

      <OnScreenComponent fallback={fallbacks.newReleases}>
        <Suspense fallback={fallbacks.newReleases}>
          <NewReleases />
        </Suspense>
      </OnScreenComponent>

      <OnScreenComponent fallback={fallbacks.latestSales}>
        <Suspense fallback={fallbacks.latestSales}>
          <LatestArtworksSales />
        </Suspense>
      </OnScreenComponent>

      <OnScreenComponent fallback={fallbacks.categories}>
        <Suspense fallback={fallbacks.categories}>
          <Categories className='container my-5'/>
        </Suspense>
      </OnScreenComponent>

      <OnScreenComponent fallback={fallbacks.prints}>
        <Suspense fallback={fallbacks.prints}>
          <Prints />
        </Suspense>
      </OnScreenComponent>

      <OnScreenComponent fallback={fallbacks.sculptures}>
        <Suspense fallback={fallbacks.sculptures}>
          <Sculptures />
        </Suspense>
      </OnScreenComponent>

      <OnScreenComponent fallback={fallbacks.searchedArtworks}>
        <Suspense fallback={fallbacks.searchedArtworks}>
          <SearchedArtworks maxCount={6}/>
        </Suspense>
      </OnScreenComponent>
    </>);
};
Home.getLayout = (page: ReactElement) => (<HomeLayout>
    <>{page}</>
  </HomeLayout>);
// export const getServerSideProps: GetServerSideProps<Props> = async ({
//   res,
// }) => {
//   res.setHeader(
//     'Cache-Control',
//     `public, s-maxage=${60 * 60}, stale-while-revalidate=${60 * 60 * 24}`
//   )
//   try {
//     const [initialFeaturedArtworks = null, initialFeaturedArtists = null] =
//       await Promise.all([fetchFeaturedArtworks(), fetchFeaturedArtists()])
//     return {
//       props: {
//         initialFeaturedArtworks,
//         initialFeaturedArtists,
//       },
//       // revalidate: 1 * 60 * 60, // 1 hours
//     }
//   } catch (err) {
//     return {
//       props: {
//         initialFeaturedArtworks: [],
//         initialFeaturedArtists: [],
//         err: JSON.stringify(err),
//         errorMessage: err instanceof Error ? err.message : undefined,
//       },
//     }
//   }
// }
const getStaticProps: GetStaticProps<Props> = async () => {
    try {
        const [initialFeaturedArtworks = null, initialFeaturedArtists = null] = await Promise.all([fetchFeaturedArtworks(), fetchFeaturedArtists()]);
        return {
            props: {
                initialFeaturedArtworks,
                initialFeaturedArtists
            },
            revalidate: 1 * 60 * 60
        };
    }
    catch (err) {
        return {
            props: {
                initialFeaturedArtworks: [],
                initialFeaturedArtists: [],
                err: JSON.stringify(err),
                errorMessage: err instanceof Error ? err.message : undefined
            }
        };
    }
};
type Prices = Record<number, Record<string, {
    lowestAsk: number | null;
    highestBid: number | null;
}>>;
export const getArtworkPrices = (artwork: ArtworkCardProps, prices?: Prices) => {
    let buyPrice = artwork.buyPrice || null;
    let sellPrice = artwork.sellPrice || null;
    if (prices && artwork.id && artwork.editionName) {
        buyPrice = prices[artwork.id]?.[artwork.editionName]?.lowestAsk;
        sellPrice = prices[artwork.id]?.[artwork.editionName]?.highestBid;
    }
    return { buyPrice, sellPrice };
};
export const fetchArtworksPrices = async (initialArtworks?: ArtworkCardProps[] | null) => {
    const prices: Prices = {};
    if (!initialArtworks?.length)
        return prices;
    const query = qs.stringify({
        filters: {
            id: {
                $in: initialArtworks.map(({ id }) => id).filter(Boolean)
            }
        },
        fields: ['id'],
        populate: {
            editions: {
                fields: ['name'],
                populate: {
                    lowestAsk: {
                        fields: ['price']
                    },
                    highestBid: {
                        fields: ['price']
                    }
                }
            }
        }
    }, { encodeValuesOnly: true });
    const artworks = await getArtworks(query);
    if (!artworks.data.length)
        return prices;
    artworks.data.forEach(artwork => {
        prices[artwork.id] = {};
        artwork.attributes.editions?.data?.forEach(edition => {
            if (!edition.attributes?.name)
                return;
            prices[artwork.id][edition.attributes.name] = {
                lowestAsk: edition.attributes.lowestAsk?.data?.attributes.price || null,
                highestBid: edition.attributes.highestBid?.data?.attributes.price || null
            };
        });
    });
    return prices;
};
export default Home;
function Slide(props: {
    title: string;
    description: string;
    cta: ReactNode;
    href: string;
}) {
    return (<>
      <h5 className='font-medium text-cool-50 sm:text-cool-800 md:hidden'>
        {props.title}
      </h5>
      <h4 className='hidden font-medium md:block xl:hidden'>{props.title}</h4>
      <h3 className='hidden font-medium xl:block'>{props.title}</h3>
      <p className='mb-5 mt-2 text-sm  text-cool-50 sm:mb-6 sm:text-cool-600 md:mt-3  md:text-base xl:text-lg'>
        {props.description}
      </p>
      <Button type='link' href={props.href} style='solid-white' className='w-fit !border-none md:hidden' pill>
        {props.cta}
        <Icon name='forward-arrow' className='ml-[10px]'/>
      </Button>
      <Button type='link' href={props.href} style='solid-white' pill size='lg' className='hidden w-fit items-center !border-none md:flex'>
        {props.cta}
      </Button>
    </>);
}

    async function __Next_Translate__getStaticProps__19200c4fd3b__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19200c4fd3b__ as getStaticProps }
  